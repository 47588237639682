import React from 'react';
import PropTypes from 'prop-types';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import Facebook from '@material-ui/icons/Facebook';
import LinkedIn from '@material-ui/icons/LinkedIn';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cx from 'classnames';

function Footer({ themeColor, contact, social, address, rightsReserved, logo, menu }) {
  const getIcon = (iconId) => {
    switch (iconId) {
      case 'Linkedin':
        return LinkedIn;
      case 'Facebook':
        return Facebook;
      case 'Twitter':
        return Twitter;
      case 'Instagram':
        return Instagram;
      default:
        break;
    }
  };

  const imageFluid = getImage(logo?.localFile);

  const socialList = social?.map((el) => ({
    ...el,
    Icon: getIcon(el.iconId),
  }));

  return (
    <div
      className={cx(
        themeColor ? `border-${themeColor}` : '',
        'w-full border-t-2 px-0 flex flex-col items-center text-center space-y-8 py-8 mt-8',
      )}
    >
      <div className="px-4 md:px-0">
        <GatsbyImage
          image={imageFluid}
          style={{ maxWidth: '350px' }}
          placeholder="none"
          alt="Citizen Shareholders"
        />
      </div>
      <div className={`bg-${themeColor} py-4 w-full flex justify-center text-center`}>{menu}</div>
      {contact && <div dangerouslySetInnerHTML={{ __html: contact }}></div>}
      {social?.length && (
        <ul className="flex justify-center p-2">
          {socialList.map(({ iconId, altText, link, Icon, color }) => (
            <li key={iconId} className="px-2">
              <a href={link}>
                <Icon
                  className="bg-white rounded-md"
                  fontSize="large"
                  style={{ color: color || 'black' }}
                />
              </a>
            </li>
          ))}
        </ul>
      )}
      {address && (
        <div className="px-4 md:px-0" dangerouslySetInnerHTML={{ __html: address }}></div>
      )}
      {rightsReserved && <div dangerouslySetInnerHTML={{ __html: rightsReserved }}></div>}
    </div>
  );
}

Footer.propTypes = {
  themeColor: PropTypes.string,
  logo: PropTypes.object,
  contact: PropTypes.string,
  social: PropTypes.array,
  address: PropTypes.string,
  rightsReserved: PropTypes.string,
  menu: PropTypes.node,
};

export default Footer;
