import PropTypes from 'prop-types';
import React from 'react';
// import Status from '@shared/components/status';
import { AppBar, Toolbar, Typography, IconButton, SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Header = ({ siteTitle, className, menuVertical, menuHorizontal }) => {
  // const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {menuVertical}
      {/* <Status /> */}
    </div>
  );

  const container = typeof document !== 'undefined' && document.body;

  return (
    <div className={className}>
      <AppBar position="static" className="shadow-none" color="secondary">
        <Toolbar disableGutters={true} className="container px-4 mx-auto my-2">
          <div
            className="flex flex-row bg-white self-stretch items-center"
            style={{ minHeight: '64px' }}
          >
            <Typography className="flex-grow py-0" style={{ width: '250px' }} variant="h1">
              <Link to="/" className="flex">
                <StaticImage
                  src="../images/csi-logo-transparent-new-colour.png"
                  width={250}
                  placeholder="none"
                  className=""
                  loading="eager"
                  alt="Citizen Shareholders"
                />
              </Link>
            </Typography>
          </div>
          <div className="flex-grow" />
          {menuHorizontal}
          {/* <Status className="hidden md:block" /> */}
          <div className="block md:hidden">
            <IconButton
              onClick={handleDrawerToggle}
              className="ml-2 mr-2 md:mr-0"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <div className="block md:hidden">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            className="text-white md:hidden"
          >
            <div style={{ width: '280px' }}>
              <Typography variant="h1" className="p-4">
                <StaticImage
                  src="../images/csi-logo-transparent-new-colour.png"
                  style={{ minWidth: '150px', maxWidth: '230px' }}
                  placeholder="none"
                  loading="eager"
                  alt="Citizen Shareholders"
                />
              </Typography>
              <IconButton
                className="mr-1 mt-1 absolute top-0 right-0 text-gray-500"
                aria-label="close"
                onClick={handleDrawerToggle}
              >
                <CloseIcon />
              </IconButton>
              {drawer}
            </div>
          </SwipeableDrawer>
        </div>
      </nav>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  className: PropTypes.string,
  menuVertical: PropTypes.node,
  menuHorizontal: PropTypes.node,
};

Header.defaultProps = {
  siteTitle: '',
  className: '',
  menuData: {},
  menuVertical: '',
  menuHorizontal: '',
};

export default Header;
