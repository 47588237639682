import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import cx from 'classnames';

function Menu({ vertical, className, itemClassName, activeBorderColor, menuData }) {
  const location = useLocation();

  return (
    <div
      className={cx(
        vertical ? 'block' : 'flex',
        vertical ? 'mt-2' : 'mt-0',
        vertical ? 'text-left' : '',
        className,
        'mr-0 md:mr-2 md:border-b-0',
      )}
    >
      {menuData?.nodes &&
        menuData?.nodes?.map((node) => {
          return (
            <span
              key={node.id}
              className={cx(
                'px-4 py-2',
                { 'w-full block': vertical },
                location.pathname === node.path
                  ? `box-border border-b-2 border-${activeBorderColor}`
                  : '',
                location.pathname !== node.path
                  ? `box-border border-b-2 border-transparent hover:border-${activeBorderColor} hover:border-opacity-25`
                  : '',
                itemClassName,
              )}
            >
              <Link
                to={node.path}
                className="leading-normal active:border-0"
                dangerouslySetInnerHTML={{ __html: node.label }}
              ></Link>
            </span>
          );
        })}
    </div>
  );
}

Menu.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  activeBorderColor: PropTypes.string,
  menuData: PropTypes.object,
};

Menu.defaultProps = {
  vertical: false,
  className: '',
  itemClassName: '',
  activeBorderColor: 'black',
  menuData: {},
};

export default Menu;
