/**
 * Layout component
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Snackbar } from '@material-ui/core';
// import Declaration from '@shared/components/wp-components/declaration';
import useLocalStorage from '@shared/hooks/use-localstorage';
import Button from '@shared/components/button';
import { Helmet } from 'react-helmet';
import 'typeface-nunito-sans';
// import StainsBg from '@shared/components/stains-bg';
import 'typeface-roboto';

const Layout = ({ children, footer, header }) => {
  const [acceptedCookies, setAcceptedCookies] = useLocalStorage('acceptedCookies');
  const [open, setOpen] = useState(!acceptedCookies);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setAcceptedCookies(true);
  };

  return (
    <>
      {/* <StainsBg /> */}
      <div className="h-full z-10">
        <Helmet>
          <html className="h-screen" lang="en"></html>
          <body className="h-screen"></body>
        </Helmet>

        <div className="h-full flex flex-col items-stretch">
          {header || ''}

          <div className="flex-grow">
            <main>{children}</main>
            <div className="snackbar-wrapper">
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
                message="We use cookies to ensure that we give you the best experience on our website."
                action={
                  <React.Fragment>
                    <Button className="bg-transparent text-white" to="/privacy-policy">
                      Privacy Policy
                    </Button>
                    <Box compnent="span" ml={2}>
                      <Button className="bg-secondary text-white" onClick={handleClose}>
                        ok
                      </Button>
                    </Box>
                  </React.Fragment>
                }
              />
            </div>
            {/* <div className="declaration-wrapper">
            <Declaration />
          </div> */}
          </div>

          {footer || ''}
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
};
Layout.defaultProps = {
  header: '',
  footer: '',
};

export default Layout;
