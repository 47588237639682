import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

function Button({ variant, className, children, to, ...rest }) {
  return (
    <React.Fragment>
      {to ? (
        <Link
          to={to}
          className={cx(
            className,
            variant === 'icon-button'
              ? 'p-3 rounded-full font-medium transition-all duration-300'
              : 'py-3 px-4 leading-3 rounded font-medium transition-all duration-300 shadow-material hover:shadow-material-hover disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none disabled:cursor-not-allowed',
          )}
          {...rest}
        >
          {children}
        </Link>
      ) : (
        <button
          className={cx(
            className,
            variant === 'icon-button'
              ? 'p-3 rounded-full font-medium transition-all duration-300'
              : 'py-3 px-4 leading-3 rounded font-medium transition-all duration-300 shadow-material hover:shadow-material-hover disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none disabled:cursor-not-allowed',
          )}
          {...rest}
        >
          {children}
        </button>
      )}
    </React.Fragment>
  );
}

export default Button;
