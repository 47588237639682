import React from 'react';
import Layout from '@shared/components/layout';
import Header from '@org/components/header';
import Footer from '@org/components/wp-components/footer';
import Menu from '@shared/components/menu';

import { useStaticQuery, graphql } from 'gatsby';

function LayoutOrg(props) {
  const { site: metadata, wpComponent: footerData, wpMenu: menuData } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      wpComponent(slug: { eq: "footer" }) {
        acfComponents {
          footer {
            themeColor
            address
            contact
            social {
              altText
              iconId
              link
              color
            }
            rightsReserved
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
      wpMenu(name: { eq: "main menu" }) {
        menuItems {
          nodes {
            id
            label
            path
            url
          }
        }
      }
    }
  `);

  return (
    <Layout
      footer={
        footerData?.acfComponents?.footer ? (
          <Footer
            className="flex-shrink"
            {...footerData?.acfComponents?.footer}
            menu={
              <Menu
                className="flex-wrap justify-center md:justify-start px-4 md:px-0 bg-tertiary"
                itemClassName="text-tertiary-contrast"
                menuData={menuData?.menuItems}
              />
            }
          />
        ) : (
          ''
        )
      }
      header={
        <Header
          className="flex-shrink"
          siteTitle={metadata?.siteMetadata?.title}
          menuVertical={
            <Menu vertical activeBorderColor="primary" menuData={menuData?.menuItems} />
          }
          menuHorizontal={
            <Menu
              className="hidden md:block -mx-4"
              activeBorderColor="primary"
              menuData={menuData?.menuItems}
            />
          }
        />
      }
      {...props}
    />
  );
}

export default LayoutOrg;
